/* .keyboard {
  font-size: large;
} */

.hg-theme-default {
  min-height: 30vh;
}

.keyboard .hg-row {
  min-height: 60px;
}

.hg-theme-default .hg-button {
  height: 60px;
  min-height: 60px;
  font-size: 28px;
}

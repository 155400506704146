/* .keyboard {
  font-size: large;
} */

.keyboard .hg-row {
  height: 5vh;
}

.hg-theme-default .hg-button {
  height: 60px;
  min-height: 60px;
  font-size: 28px;
}

/* Custom style for the keyboard */
.ktmart-theme .hg-button-space {
  width: 50%;
}

.ktmart-theme .hg-row:nth-child(1),
.ktmart-theme .hg-row:nth-child(1) .hg-button {
  height: 50px;
  min-height: 50px;
}

.ktmart-theme .hg-button.hg-functionBtn,
.ktmart-theme .hg-row:last-child .hg-standardBtn.gray {
  background-color: #cdd1d7;
  font-size: 16px;
}

.ktmart-theme .hg-button.hg-functionBtn.hg-button-enter {
  background-color: #2d6ef1;
  color: #fff;
}

.ktmart-theme .hg-button.hg-functionBtn.hg-button-clear {
  background-color: #e53737;
  color: #fff;
}

.ktmart-theme .hg-button.align-left {
  justify-content: flex-start;
  padding: 20px;
}

.ktmart-theme .hg-button.align-right {
  justify-content: flex-end;
  padding: 20px;
}

.MuiPaper-root {
  width: 500px;
  height: 500px;
  font-size: 30px;
}

.sc-htoDjs.qlSAn {
  display: none !important;
}

.operation-bar {
  display: grid;
  grid-template-columns: 1.1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 4px 4px;
  grid-auto-flow: row;
  grid-template-areas:
    ". ."
    "confirm confirm"
    "cancel cancel";
  align-self: stretch;
}

.confirm {
  grid-area: confirm;
}

.cancel {
  grid-area: cancel;
}

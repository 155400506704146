.MuiPaper-root.fpKsPY {
  width: 400px !important;
  height: 500px !important;
  font-size: 30px;
  background: #597785;
  padding: 10px;
  border-radius: 5px;
}

.MuiPaper-root.fpKsPY>.eHKVGO {
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 10px;
}

.jfdoYu * {
  font-family: 'DINPro', sans-serif;
  font-weight: bold;
}

.jeiWCh {
  background-color: #597785;
  /* margin: 5px; */
}

.jeiWCh button {
  border: none;
  /* margin: 5px; */
}



.jeiWCh >.hZqhPI > .MuiButton-label {
  height: 90px;
  background-color: #ECEFF1;
  width: 117px;
  border-radius: 5px;
}


.sc-htoDjs.qlSAn {
  display: none !important;
}
/*文字不可选中*/
body {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.yellow {
  background-color: yellow;
}

.customerscreen {
  height: 0;
  width: 0;
  opacity: 0;
  display: none;
}

.normalLabel {
  font-family: 'PingFangSC-Regular', sans-serif;
  font-size: 18px;
  color: #6B7C91;
}

.normalValue {
  font-family: 'DINPro', sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: #333333;
}

.pageTitle {
  font-family: 'PingFangSC-Medium', sans-serif;
  font-size: 24;
  /* font-weight: 300; */
  color: #2C6EF1;
  margin-left: 6px;
}

.pageIcon {
  width: 20px;
  height: 20px;
  margin: 0 10px;
}

.pageIcon16 {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.viewDetailsButton {
  width: 106px;
  height: 50px;
  background-color: #39bc7c;
  color: #fff;
  border: 0px;
  border-radius: 4px;
  font-family: 'PingFangSC-Regular', sans-serif;
  font-size: 16;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
}

.paidStatus {
  width: 60px;
  height: 50px;
  background-color: #F88B16;
  color: #fff;
  border: 0px;
  border-radius: 4px;
  font-family: 'PingFangSC-Regular', sans-serif;
  font-size: 16;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
}

.printDetailsButton {
  width: 60px;
  height: 50px;
  background-color: #2C6EF1;
  color: #fff;
  border: 0px;
  border-radius: 4px;
  font-family: 'PingFangSC-Regular', sans-serif;
  font-size: 16;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
  margin-right: 5px;
}

.scanToRefundButton {
  width: 140px;
  height: 50px;
  background-color: #ED4141;
  color: #fff;
  border: 0px;
  border-radius: 4px;
  font-family: 'PingFangSC-Regular', sans-serif;
  font-size: 16;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
  margin-right: 5px;
}

.pageSearchButton {
  width: 78px;
  height: 28px;
  background-color: #2C6EF1;
  color: #fff;
  border: 0px;
  border-radius: 4px;
}

.pageSearchBar>.ant-input-group>.ant-input-group-addon:last-child {
  background-color: rgba(0, 0, 0, 0.0);
}

.pageSearchBar>.ant-input-group>.ant-input-group-addon:last-child>.ant-input-search-button:not(.ant-btn-primary) {
  background-color: #2C6EF1;
  border-radius: 16px;
  height: 28px;
  position: relative;
  left: -41px;
}

.pageSearchBar>.ant-input-group>.ant-input-group-addon:last-child>.ant-input-search-button:not(.ant-btn-primary)>.anticon {
  color: #FFFFFF;
}

.pageSearchBar>.ant-input-group>.ant-input:first-child {
  color: rgba(0, 0, 0, 0.45);
  border-radius: 16px;
  width: 250px;
  height: 32px;
}

.detailsPageHeader {
  border: 1px solid #979797;
  border-radius: 15px;
  background-color: #fff;
  height: 100%;
  /* max-height: 900px; */
  padding: 10px 0px;
}

.detailNo {
  font-family: 'PingFangSC-Medium', sans-serif;
  font-size: 20;
  font-weight: 300;
  color: #2C6EF1;
  background-image: linear-gradient(to right, #D0E0FF, #fff);
  padding-left: 10px;
  min-width: 200px;
  position: absolute;
  left: 0px;
  /* top: 25px; */
}

.pageMainTable>.ant-spin-nested-loading>.ant-spin-container>.ant-table>.ant-table-container>.ant-table-header table>.ant-table-thead>tr th {
  color: #6B7C91;
  font-size: 12;
  font-weight: 400;
  background: #DAE2EC;
  padding: 2px 2px;
  text-align: center;
  border-top: 1px solid #979797;
}

.pageMainTable>.ant-spin-nested-loading>.ant-spin-container>.ant-table>.ant-table-container>.ant-table-header table>.ant-table-thead>tr th::before {
  background-color: #fff;
}

.pageMainTable>.ant-spin-nested-loading>.ant-spin-container>.ant-table {
  background-color: #F0F4F9;
  border: #2C6EF1;
}

.pageMainTable>.ant-spin-nested-loading>.ant-spin-container>.ant-table>.ant-table-container>.ant-table-header table>.ant-table-thead>tr:first-child th:first-child {
  border-top-left-radius: 12px;
  border-left: 1px solid #979797;
}

.pageMainTable>.ant-spin-nested-loading>.ant-spin-container>.ant-table>.ant-table-container>.ant-table-header table>.ant-table-thead>tr:first-child th:last-child {
  border-top-right-radius: 12px;
  border-right: 1px solid #979797;
}

.pageMainTable>.ant-spin-nested-loading>.ant-spin-container>.ant-table>.ant-table-container>.ant-table-body {
  border-left: 1px solid #979797;
  border-right: 1px solid #979797;
  border-bottom: 1px solid #979797;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.pageMainTable>.ant-spin-nested-loading>.ant-spin-container>.ant-table>.ant-table-container>.ant-table-body table tbody tr td {
  font-family: 'DINPro', sans-serif;
  font-size: 18;
  font-weight: 500;
  padding: 2px 2px;
  height: 70px;
}

.pageMainTable>.ant-spin-nested-loading>.ant-spin-container>.ant-table>.ant-table-container>.ant-table-body table tbody tr td:last-child {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}




.pageDetailTable>.ant-spin-nested-loading>.ant-spin-container>.ant-table>.ant-table-container>.ant-table-header table>.ant-table-thead>tr th {
  color: #6B7C91;
  font-size: 12;
  font-weight: 400;
  background: #DAE2EC;
  padding: 2px 2px;
  text-align: center;
  border-top: 1px solid #979797;
}

.pageDetailTable>.ant-spin-nested-loading>.ant-spin-container>.ant-table>.ant-table-container>.ant-table-header table>.ant-table-thead>tr th::before {
  background-color: #fff;
}

.pageDetailTable>.ant-spin-nested-loading>.ant-spin-container>.ant-table>.ant-table-container>.ant-table-header table>.ant-table-thead>tr:first-child th:first-child {
  border-top-left-radius: 12px;
  border-left: 1px solid #979797;
}

.pageDetailTable>.ant-spin-nested-loading>.ant-spin-container>.ant-table>.ant-table-container>.ant-table-header table>.ant-table-thead>tr:first-child th:last-child {
  border-top-right-radius: 12px;
  border-right: 1px solid #979797;
}

.pageDetailTable>.ant-spin-nested-loading>.ant-spin-container>.ant-table>.ant-table-container>.ant-table-body {
  border-left: 1px solid #979797;
  border-right: 1px solid #979797;
  border-bottom: 1px solid #979797;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  height: 700px;

}
.pageDetailTableHeight900>.ant-spin-nested-loading>.ant-spin-container>.ant-table>.ant-table-container>.ant-table-body {
  height: 900px;

}

.pageDetailTable>.ant-spin-nested-loading>.ant-spin-container>.ant-table>.ant-table-container>.ant-table-body table tbody tr td {
  font-family: 'DINPro', sans-serif;
  font-size: 18;
  font-weight: 500;
  padding: 2px 2px;
  height: 70px;
}

.pageDetailTable>.ant-spin-nested-loading>.ant-spin-container>.ant-table>.ant-table-container>.ant-table-body table tbody tr td:first-child {
  color: #9BADC3;
  text-align: center;
}

.pageDetailTable>.ant-spin-nested-loading>.ant-spin-container>.ant-table>.ant-table-container table>.ant-table-thead>tr th {
  color: #6B7C91;
  font-size: 12px;
  font-weight: 400;
  background: #EDEEF0;
  padding: 2px 2px;
  text-align: center;
  border-top: 1px solid #979797;
}

.pageDetailTable>.ant-spin-nested-loading>.ant-spin-container>.ant-table>.ant-table-container>.ant-table-content table>.ant-table-thead>tr th::before {
  background-color: #fff;
}

.pageDetailTable>.ant-spin-nested-loading>.ant-spin-container>.ant-table {
  background-color: #fff;
  border: #2C6EF1;
}

.pageDetailTable>.ant-spin-nested-loading>.ant-spin-container>.ant-table>.ant-table-container>.ant-table-content table thead>tr:first-child th:first-child {
  border-top-left-radius: 12px;
  border-left: 1px solid #979797;
}

.pageDetailTable>.ant-spin-nested-loading>.ant-spin-container>.ant-table>.ant-table-container>.ant-table-content table>.ant-table-thead>tr:first-child th:last-child {
  border-top-right-radius: 12px;
  border-right: 1px solid #979797;
}

.pageDetailTable>.ant-spin-nested-loading>.ant-spin-container>.ant-table>.ant-table-container>.ant-table-content table>.ant-table-tbody tr td:first-child {
  border-left: 1px solid #979797;
}

.pageDetailTable>.ant-spin-nested-loading>.ant-spin-container>.ant-table>.ant-table-container>.ant-table-content table>.ant-table-tbody tr td:last-child {
  border-right: 1px solid #979797;
}

.pageDetailTable>.ant-spin-nested-loading>.ant-spin-container>.ant-table>.ant-table-container>.ant-table-content table>.ant-table-tbody tr:last-child td {
  border-bottom: 1px solid #979797;
}

.pageDetailTable>.ant-spin-nested-loading>.ant-spin-container>.ant-table>.ant-table-container>.ant-table-content table>.ant-table-tbody tr:last-child td:first-child {
  border-bottom-left-radius: 12px;
}

.pageDetailTable>.ant-spin-nested-loading>.ant-spin-container>.ant-table>.ant-table-container>.ant-table-content table>.ant-table-tbody tr:last-child td:last-child {
  border-bottom-right-radius: 12px;
}

.pageDetailTable>.ant-spin-nested-loading>.ant-spin-container>.ant-table>.ant-table-container>.ant-table-content table tbody tr td {
  font-family: 'DINPro', sans-serif;
  font-size: 18;
  font-weight: 500;
  padding: 2px 2px;
  height: 70px;
}

.pageDetailTable>.ant-spin-nested-loading>.ant-spin-container>.ant-table>.ant-table-container>.ant-table-content table tbody tr td:last-child {
  display: flex;
  justify-content: center;
  align-items: center;
}


.pageMainTableHighLine>.ant-spin-nested-loading>.ant-spin-container>.ant-table>.ant-table-container>.ant-table-body table tbody tr td {
  height: 100px;
}


.detailDescriptions {
  margin: 0.5rem;
  padding: 0.5rem;
  background-color: #F0F4F9;
  border-radius: 4px;
  display: flex;
  align-items: center;
  min-height: 40px;
}

.detailDescLabel {
  width: 60%;
  font-family: 'PingFangSC-Semibold', sans-serif;
  font-size: 18;
  color: #6B7C91;
}

.detailDescValue {
  width: 40%;
  font-family: 'PingFangSC-Semibold', sans-serif;
  font-size: 24;
  color: #333333;
}


.detailDescriptionsSmall {
  margin: 5px;
  padding: 5px;
  background-color: #F0F4F9;
  border-radius: 4px;
  display: flex;
  align-items: center;
  height: 30px;
}

.detailDescLabelSmall {
  width: 60%;
  font-family: 'PingFangSC-Semibold', sans-serif;
  font-size: 16;
  color: #6B7C91;
}

.detailDescValueSmall {
  width: 40%;
  font-family: 'PingFangSC-Semibold', sans-serif;
  font-size: 16;
  color: #333333;
}


.rowStyle1 {
  background-color: #F0F4F9;
}

.rowStyle2 {
  background-color: #fff;
}

.modalConfirm>.ant-modal-content {
  border-radius: 10px;
  font-family: 'PingFangSC-Semibold', sans-serif;
  font-size: 20px;
  font-weight: 300;
  /* color: #333333; */

  /* width: 924px;
  height: 922px; */
}

.modalPage>.ant-modal-content {
  border-radius: 10px;
  /* width: 924px;
  height: 922px; */
}

.modalPage>.ant-modal-content>.ant-modal-header {
  border-radius: 10px;
}

.modalPage>.ant-modal-content>.ant-modal-header>.ant-modal-title {
  text-align: center;
  font-family: 'PingFangSC-Semibold', sans-serif;
  font-size: 24px;
  color: #2c6ef1;
}

.modalTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'PingFangSC-Semibold', sans-serif;
  font-size: 24px;
  color: #2c6ef1;
}

.modalDivider {
  background-color: #2c6ef1;
  height: 3px;
}

.modalPrintButton {
  background-color: #2c6ef1;
  color: #fff;
  font-family: 'PingFangSC-Semibold', sans-serif;
  font-size: 18px;
  width: 250px;
  height: 50px;
  border-radius: 6px;
  border: 0px;
}

.modalNextTransButton {
  background-color: #32b373;
  color: #fff;
  font-family: 'PingFangSC-Semibold', sans-serif;
  font-size: 18px;
  width: 250px;
  height: 50px;
  border-radius: 6px;
  border: 0px;
  margin-top: 40px;
}

.receiptCanvas {
  background-color: #f2f2f2;
  border-radius: 10px;
  padding: 10px;
  width: 500px;
  height: 720px;
  overflow: auto;
}

.comment {
  font-family: 'PingFangSC-Semibold', sans-serif;
  font-size: 24px;
}

.dangerButton {
  font-size: 25px;
  height: 'auto';
  width: 150px;
  white-space: normal;
  border: 0px;
  border-radius: 6px;
  background-color: #ED4141;
  color: #fff;
}

.normalButton {
  font-size: 25px;
  height: 'auto';
  width: 150px;
  white-space: normal;
  border: 0px;
  border-radius: 6px;
  background-color: #E3ECFF;
  color: #2C6EF1;
}

.drawer {
  border-radius: 10px;
  font-family: 'PingFangSC-Semibold', sans-serif;
  font-size: 20;
  font-weight: 300;
}

.drawer>.ant-drawer-content-wrapper>.ant-drawer-content>.ant-drawer-wrapper-body>.ant-drawer-header>.ant-drawer-header-title>.ant-drawer-title {
  font-size: 24;
  /* font-weight: 300; */
  color: #2C6EF1;
  margin-left: 6px;
}

.customerscreenContainer {
  display: grid;
  grid-template-columns: 54% 45%;
  /* grid-template-columns: 1.1fr 0.9fr; */
  grid-template-rows: 16% 3% 26% 50%;
  /* grid-template-rows: 0.2fr 0.2fr 1.6fr 1.6fr; */
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  gap: 5px 5px;
  grid-auto-flow: row;
  grid-template-areas:
    "reminder total"
    "title total"
    "list total"
    "list last";
}
/* .customerscreenContainer {
  display: grid;
  grid-template-columns: 54% 45%;
  grid-template-columns: 1.1fr 0.9fr;
  grid-template-rows: 5.5% 5.5% 44% 44%;
  grid-template-rows: 0.2fr 0.2fr 1.6fr 1.6fr;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  gap: 5px 5px;
  grid-auto-flow: row;
  grid-template-areas:
    "reminder reminder"
    "title title"
    "list total"
    "list last";
} */

.customerscreenReminder {
  grid-area: reminder;
  /* background-color: #2C6EF1; */
}

.customerscreenTitle {
  grid-area: title;
  display: flex;
  align-items: center;
  padding-left: 5px;
  /* background-color: #32b373; */
}

.customerscreenTotal {
  grid-area: total;
  /* background-color: #2C6EF1; */
  border: 1px solid #979797;
  border-radius: 15px;
  background-color: #fff;
  padding: 5px;
}

.customerscreenTotal>.ant-page-header {
  padding: 5px 0px;
  background-color: #fff;
  /* background-color: rgb(170, 46, 46); */
}

.customerscreenList {
  grid-area: list;
  border: 1px solid #979797;
  border-radius: 15px;
  background-color: #fff;
  padding: 0px;
}

.customerscreenLast {
  grid-area: last;
  border: 1px solid #979797;
  border-radius: 15px;
  background-color: #fff;
  padding: 5px;
  ;
}

.customerscreenLast>.ant-page-header {
  padding: 5px 0px;
  background-color: #fff;
}


.reminder {
  height: 40px;
  border: 0px;
  border-radius: 20px;
  margin: 10px 0;
  padding-left: 10px;
  font-size: 18;
  display: flex;
  align-items: center;
}

.errorReminder {
  background-color: #FFE8E8;
  color: #ED4141;
  width: 530px;
}

.checkoutContainer {
  /* background-color: #2C6EF1; */
  display: grid;
  grid-template-columns: 50% 49%;
  grid-template-rows: 4% 677px 150px;
  /* grid-template-rows: 0.2fr 3fr 217px; */
  /* grid-template-rows: 0.2fr 0.2fr 3.6fr; */
  grid-auto-columns: 1fr;
  gap: 5px 5px;
  grid-auto-flow: row;
  grid-template-areas:
    "checkoutCartReminder checkoutProductReminder"
    /* "checkoutCartTitle checkoutProductList" */
    "checkoutCart checkoutProductList"
    "checkoutOperation checkoutProductList";
}

.checkoutCart {
  grid-area: checkoutCart;
  margin-left: 5px;
}

.checkoutOperation {
  grid-area: checkoutOperation;
  margin-left: 5px;
  /* background-color: #fff; */
}

.checkoutCartReminder {
  grid-area: checkoutCartReminder;
  background-color: #DAE2EC;
  color: #2C6EF1;
  margin-left: 5px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.checkoutCartTitle {
  grid-area: checkoutCartTitle;
  display: flex;
  align-items: center;
  margin-left: 5px;
}

.checkoutProductReminder {
  grid-area: checkoutProductReminder;
  background-color: #DAE2EC;
  color: #2C6EF1;
  margin-right: 5px;
  justify-content: space-between;
  /* justify-content: flex-end; */
  /* margin-right: 5px; */
}

.checkoutProductList {
  grid-area: checkoutProductList;
}



.productPageList {
  border: 1px solid #A8B3C4;
  border-radius: 10px;
  background-color: #fff;
  /* display:flexbox; */
  /* padding: 1px; */
}

.productCategories {
  border: 1px solid #A8B3C4;
  border-radius: 10px;
  background-color: #DEE5EF;
  height: 255px;
  margin-top: 10px;
  padding: 10px;
}

.cardProductName {
  overflow: hidden;
  line-height: 14px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  width: 100%;
  height: 45px;
  font-family: 'PingFangSC-Medium', sans-serif;
  font-size: 14px;
  word-wrap: break-word;
}

.cardProductNameNoImage {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  width: 100px;
  height: 105px;
  font-family: 'PingFangSC-Medium', sans-serif;
  font-size: 14;
  word-wrap: break-word;
}

.productPagination {
  width: 34px;
  height: 34px;
  border: none;
  border-radius: 5px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  white-space: nowrap;
}
.productPagination2 {
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 15px;
  white-space: nowrap;
}

.bigCategory {
  width: 49%;
  height: 55px;
  margin-left: 3px;
  margin-right: 3px;
  border: none;
  border-radius: 12px;
  background-color: #B9CBEE;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  font-family: 'PingFangSC-Semibold';
  font-size: 16;
  color: #2C6EF1;
}

.categoryList>.ant-spin-nested-loading>.ant-spin-container>.ant-row div>.ant-col>.ant-list-item {
  margin-bottom: 5px;
  font-size: 18;
}

.categoryCard>.ant-card-body {
  padding: 0px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.categoryListPagination {
  width: 49%;
  height: 80px;
  background-color: #B8C7DD;
  border: 0px;
  /* border: 1px solid #979797; */
  border-radius: 4px;
}

.emptyProduct {
  width: 100%;
  /* height: 75%; */
  height: 77.5%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #A8B3C4;
  border-radius: 10px;
  background-color: #fff;
}

.checkoutOperationBox {
  border: 1px solid #A8B3C4;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.staffOperateContainer {
  display: grid;
  /* background-color: #2C6EF1; */
  grid-template-columns: 94px 94px 94px;
  grid-template-rows: 103px 103px;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  gap: 5px 5px;
  grid-auto-flow: row;
  grid-template-areas:
    "staffOperate11 staffOperate12 staffOperate13"
    "staffOperate22 staffOperate22 staffOperate22";
    /* "staffOperate21 staffOperate22 staffOperate22"; */
}

.staffOperate11 {
  grid-area: staffOperate11;
}

.staffOperate12 {
  grid-area: staffOperate12;
}

.staffOperate21 {
  grid-area: staffOperate21;
}

.staffOperate13 {
  grid-area: staffOperate13;
}

.staffOperate22 {
  grid-area: staffOperate22;
}

.staffOperateButton {
  width: 100%;
  height: 100%;
  color: #fff;
  border: 0px;
  border-radius: 5px;
  font-family: 'PingFangSC-Regular', sans-serif;
  font-size: 18;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
}

.newCustomerContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 65px 65px;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  gap: 5px 5px;
  grid-auto-flow: row;
  grid-template-areas:
    "newCustomerContainerL1C1 newCustomerContainerL1C2"
    "newCustomerContainerL2C1 newCustomerContainerL2C2";
  font-family: 'PingFangSC-Regular', sans-serif;
  font-size: 18;
}

.newCustomerContainerL1C1 {
  grid-area: newCustomerContainerL1C1;
}

.newCustomerContainerL1C2 {
  grid-area: newCustomerContainerL1C2;
}

.newCustomerContainerL2C1 {
  grid-area: newCustomerContainerL2C1;
}

.newCustomerContainerL2C2 {
  grid-area: newCustomerContainerL2C2;
}


.doublePageContainerCustomerInvoice {
  display: grid;
  grid-template-columns: 49% 49%;
  grid-template-rows: 5% 86% 8%;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  gap: 5px 5px;
  grid-auto-flow: row;
  grid-template-areas:
    "doublePage01 doublePage01"
    "doublePage03 doublePage04"
    "doublePage05 doublePage04";
    /* "doublePage05 doublePage06"; */
}
.doublePageContainer {
  display: grid;
  grid-template-columns: 49% 49%;
  grid-template-rows: 5% 86% 8%;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  gap: 5px 5px;
  grid-auto-flow: row;
  grid-template-areas:
    "doublePage01 doublePage02"
    "doublePage03 doublePage04"
    "doublePage05 doublePage04";
    /* "doublePage05 doublePage06"; */
}


.doublePage01 {
  grid-area: doublePage01;
  width: 100%;
  display: flex;
  align-items: center;
  /* background-color: #2C6EF1; */
}

.doublePage02 {
  grid-area: doublePage02;
  width: 100%;
  display: flex;
  align-items: center;
}

.doublePage03 {
  grid-area: doublePage03;
  /* background-color: #2C6EF1; */
}

.doublePage04 {
  grid-area: doublePage04;
  /* background-color: #2C6EF1; */
}
.doublePage05 {
  grid-area: doublePage05;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.doublePage06 {
  grid-area: doublePage06;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}


.doubleColumnContainer {
  display: grid;
  grid-template-columns: 20% 79%;
  grid-template-rows: 1.7fr 0.2fr 0.1fr;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  gap: 5px 5px;
  grid-auto-flow: row;
  grid-template-areas:
    "double01 double02"
    "doubleColumnTitle doubleColumnTitle"
    "doubleColumnBottom doubleColumnBottom";
}

.doubleColumnTitle {
  grid-area: doubleColumnTitle;
  /* padding: 10px 0 10px 0; */
  display: flex;
  align-items: center;
}

.double01 {
  grid-area: double01;
  /* background-color: #fff;
  border-radius: 20px;
  padding: 10px 0 10px 0;
  min-height: none; */
}

.double02 {
  grid-area: double02;
  background-color: #2C6EF1;
}

.doubleColumnBottom {
  grid-area: doubleColumnBottom;
  padding: 10px 0 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkedMenu {
  display: flex;
  align-items: center;
  background-color: #2C6EF1;
  border: none;
  height: 50px;
  width: 220px;
  border-radius: 0 25px 25px 0;
  font-family: 'PingFangSC-Semibold';
  font-size: 20px;
  color: #fff;
  margin-top: 20px;
  margin-bottom: 20px;
}

.unCheckedMenu {
  display: flex;
  align-items: center;
  background-color: #fff;
  border: none;
  height: 50px;
  width: 190px;
  border-radius: 0 25px 25px 0;
  font-family: 'PingFangSC-Semibold';
  font-size: 16px;
  color: #333333;
  margin-top: 10px;
  margin-bottom: 10px;
}

.unCheckedMenu2 {
  display: flex;
  align-items: center;
  background-color: #fff;
  border: none;
  height: 50px;
  width: 190px;
  border-radius: 25px 0 0 25px;
  font-family: 'PingFangSC-Semibold';
  font-size: 16px;
  color: #333333;
  margin-top: 10px;
  margin-bottom: 10px;
}

/* 
.normalInput {
  background-color: #F3F3F3;
  width: 500px;
  border: none;
  border-radius: 4px;
} */

.rotateRight {
  transform: rotate(90deg);

}